import React from 'react'
import Layout from '../components/Layout'
import FooterContact from './../components/FooterContact'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/seguridad.png'
import PageContentSeguridad from '../components/Solutions/Pages/Seguridad'

const Seguridad = props => (
  <Layout
    title="Servicio de Seguridad Social"
    description="Nuestra Solución en Seguridad Social permite disminuir el riesgo en la materia sin comprometer los beneficios y derechos de tus trabajadores."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <PageContentSeguridad />
    <FooterContact />
  </Layout>
)

export default Seguridad
